<template>
  <v-row class="pb-14">
    <v-app-bar class="pt-4 pl-6 pr-8 pb-11" flat height="35" app fixed style="top: 56px; z-index: 5; transition: none !important" :style="`${scroll > 80 ? 'box-shadow: 2px 4px 6px -6px rgba(69, 90, 100, 0.15) !important;' : ''};`">
      <v-toolbar-title class="d-block subtitle-1 font-weight-medium">Panel de cumplimiento</v-toolbar-title>
    </v-app-bar>

    <v-col class="px-0 pt-8" cols="12">
      <v-row>
        <v-col cols="4">
          <v-row>
            <!-- qualification -->
            <v-col cols="12">
              <v-card flat>
                <v-card-text class="gradient--blue">
                  <v-row>
                    <v-col cols="6">
                      <v-avatar class="ml-n2" size="64"><img :src="require(`@/assets/product-compliance/appAsset--compliance--rate--good.svg`)"></v-avatar>
                      <span class="d-block text-h1 white--text font-weight-medium">9.6</span>
                      <span class="d-block body-2 white--text font-weight-medium">Evaluación de cumplimiento</span>
                    </v-col>
                  </v-row>
                  <div class="planet overflow-hidden">
                    <!-- <div class="planet-back"></div>
                    <div class="planet-front"></div> -->
                  </div>
                </v-card-text>
                <v-card-text class="py-3">
                  <v-row>
                    <v-col cols="6">
                      <span class="d-block mt-2 mb-2 subtitle-2">Resumen mensual</span>
                      <span class="d-block subtitle-1 text--primary font-weight-medium">0 días</span>
                      <span class="d-block body-1">con descuadres</span>
                      <chart-bar class="mt-n3 ml-n3" :height="60" :chart-data="month" :display-axes-x="false" />
                    </v-col>
                    <v-col cols="6" class="v-col--outlined">
                      <span class="d-block mt-2 mb-2 subtitle-2">Resumen semanal</span>
                      <span class="d-block subtitle-1 text--primary font-weight-medium">0 días</span>
                      <span class="d-block body-1">con descuadres</span>
                      <chart-bar class="mt-n3 ml-n4" :height="60" :chart-data="week" :display-axes-x="false" />
                    </v-col>
                  </v-row>
                  <v-divider class="my-3" />
                  <v-row>
                    <v-col cols="12">
                      <span class="d-block mt-1 mb-2 subtitle-2">Proceso activo</span>
                      <v-progress-linear buffer-value="80" value="65" stream color="green darken-1" rounded></v-progress-linear>
                    </v-col>
                  </v-row>
                  <v-row class="pb-3 text-center">
                    <v-col>
                      <span class="d-block mb-3 body-2 text--primary font-weight-medium">Sistema</span>
                      <v-icon color="primary" left size="22">mdi-printer-pos-outline</v-icon>PoS
                    </v-col>
                    <v-divider vertical />
                    <v-col>
                      <span class="d-block mb-3 body-2 text--primary font-weight-medium">Avance</span>
                      <v-icon color="primary" left size="18">mdi-timer-sand-complete</v-icon>65%
                    </v-col>
                    <v-divider vertical />
                    <v-col>
                      <span class="d-block mb-3 body-2 text--primary font-weight-medium">Tiempo restante</span>
                      <v-icon color="primary" left size="18">mdi-clock-outline</v-icon>43 mins.
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- end qualification -->

            <!-- operation -->
            <v-col cols="12">
              <v-card flat>
                <v-card-title>
                  <span class="subtitle-2 text--primary font-weight-medium"><v-icon color="primary" left>mdi-forum</v-icon>Actividad</span>
                </v-card-title>
                <v-divider />
                <v-card-text class="py-0">
                  <v-list three-line>
                    <template v-for="(item, i) in chats">
                      <v-divider class="ml-14" v-if="i > 0" :key="i" inset></v-divider>
                      <v-list-item :key="item.title" :to="{ name: 'ComplianceTask', query: { ref: 'task_dialog', activity: 'True' } }">
                        <v-list-item-avatar>
                          <v-img :src="item.avatar"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-html="item.title"></v-list-item-title>
                          <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- end operation -->
          </v-row>
        </v-col>

        <v-col cols="8">
          <v-row>
            <!-- tasks -->
            <v-col cols="12">
              <v-card flat>
                <v-card-title>
                  <span class="subtitle-2 text--primary font-weight-medium"><v-icon color="primary" left>mdi-tools</v-icon>Resumen de las tareas</span>
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-hover v-slot="{ hover }">
                        <v-sheet @click="$router.push({ name: 'ComplianceTask', query: { status: 'unassigned' } })" class="pa-4 text-center cursor-pointer" :elevation="hover ? 6 : 0" color="blue-grey lighten-5" outlined rounded="md" width="100%">
                          <v-list-item>
                            <v-list-item-avatar class="mr-2">
                              <v-avatar color="blue-grey lighten-4" size="36">
                                <v-icon color="blue-grey darken-1" size="18">mdi-account-off</v-icon>
                              </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="text-h2 blue-grey--text darken-1 font-weight-regular">6</v-list-item-title>
                              <v-list-item-subtitle class="d-block body-1 blue-grey--text darken-1">sin asignar</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-sheet>
                      </v-hover>
                    </v-col>
                    <v-col cols="3">
                      <v-hover v-slot="{ hover }">
                        <v-sheet @click="$router.push({ name: 'ComplianceTask', query: { status: 'wip' } })" class="pa-4 text-center cursor-pointer" :elevation="hover ? 6 : 0" color="primary lighten-5" outlined rounded="md" width="100%">
                          <v-list-item>
                            <v-list-item-avatar class="mr-2">
                              <v-avatar color="primary lighten-4" size="36">
                                <v-icon color="primary darken-1" size="18">mdi-progress-clock</v-icon>
                              </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="text-h2 primary--text darken-1 font-weight-regular">4</v-list-item-title>
                              <v-list-item-subtitle class="d-block body-1 primary--text darken-1">en progreso</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-sheet>
                      </v-hover>
                    </v-col>
                    <v-col cols="3">
                      <v-hover v-slot="{ hover }">
                        <v-sheet @click="$router.push({ name: 'ComplianceTask', query: { status: 'review' } })" class="pa-4 text-center cursor-pointer" :elevation="hover ? 6 : 0" color="amber lighten-5" outlined rounded="md" width="100%">
                          <v-list-item>
                            <v-list-item-avatar class="mr-2">
                              <v-avatar color="amber lighten-4" size="36">
                                <v-icon color="yellow darken-2" size="18">mdi-eye-outline</v-icon>
                              </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="text-h2 yellow--text darken-3 font-weight-regular">8</v-list-item-title>
                              <v-list-item-subtitle class="d-block body-1 yellow--text darken-3">en revisión</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-sheet>
                      </v-hover>
                    </v-col>
                    <v-col cols="3">
                      <v-hover v-slot="{ hover }">
                        <v-sheet @click="$router.push({ name: 'ComplianceTask', query: { status: 'wait' } })" class="pa-4 text-center cursor-pointer" color="red lighten-5" :elevation="hover ? 6 : 0" outlined rounded="md" width="100%">
                          <v-list-item>
                            <v-list-item-avatar class="mr-2">
                              <v-avatar color="deep-orange lighten-4" size="36">
                                <v-icon color="deep-orange darken-2" size="18">mdi-clock-alert-outline</v-icon>
                              </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="text-h2 deep-orange--text darken-3 font-weight-regular">2</v-list-item-title>
                              <v-list-item-subtitle class="d-block body-1 deep-orange--text darken-3">postergadas</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-sheet>
                      </v-hover>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-row align="center" justify="center">
                    <v-btn class="my-4" color="primary" :to="{ name: 'ComplianceTask' }">Ir al Task Manager<v-icon right>mdi-arrow-right</v-icon></v-btn>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
            <!-- end tasks -->

            <!-- process -->
            <v-col cols="12">
              <v-card flat>
                <v-card-title class="subtitle-2 text--primary font-weight-medium">
                  <v-icon color="primary" left>mdi-pipe</v-icon>Descripción general de procesos
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row align="center" justify="center" no-gutters>
                    <v-col cols="4">
                      <v-sheet class="px-3 py-1 my-3" outlined rounded="md" width="200">
                        <v-list-item>
                          <v-list-item-icon class="mr-3"><img :src="require(`@/assets/brands/brand--ncr.svg`)" alt="brand--sap" height="28"></v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title class="subtitle-2 text--primary">Tiendas / PoS</v-list-item-title>
                            <v-list-item-subtitle class="body-1 text--secondary">NCR</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <span class="flow-connector--1-3--active"><img :src="require(`@/assets/product-compliance/appAsset--compliance--flow-connector--blue.svg`)"></span>
                      </v-sheet>
                      <v-sheet class="px-3 py-1 my-3" outlined rounded="md" width="200">
                        <v-list-item>
                          <v-list-item-icon class="mr-3"><img :src="require(`@/assets/brands/brand--sap.svg`)" alt="brand--sap" height="28"></v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title class="subtitle-2 text--primary">Back-office</v-list-item-title>
                            <v-list-item-subtitle class="body-1 text--secondary">SAP</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <span class="flow-connector--2-3--active"><img :src="require(`@/assets/product-compliance/appAsset--compliance--flow-con-2-3--active--blue.svg`)"></span>
                      </v-sheet>
                      <v-sheet class="px-3 py-1 my-3 connector--warning" outlined rounded="md" width="200">
                        <v-tooltip color="bgSearch" right max-width="280px" transition="scale-transition" nudge-right="30">
                          <template v-slot:activator="{on}">
                            <v-list-item v-on="on" :to="{ name: 'ComplianceTask', query: { destination: 'whm' } }">
                              <v-list-item-icon class="mr-3"><img :src="require(`@/assets/brands/brand--korber.svg`)" alt="brand--sap" height="28"></v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title class="subtitle-2 text--primary">C. Distribución</v-list-item-title>
                                <v-list-item-subtitle class="body-1 text--secondary">Korber</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <span v-on="on" class="flow-destination--3-3--failed"><img :src="require(`@/assets/product-compliance/appAsset--compliance--flow-connector--failed--yellow.svg`)"></span>
                          </template>
                          <span class="d-block px-4 py-3 body-1 text--secondary">Ver en el Task Manager</span>
                        </v-tooltip>
                      </v-sheet>
                    </v-col>
                    <v-col class="py-6 text-center flow" cols="4">
                      <v-sheet class="my-16 pt-4 pb-2 mx-auto v-sheet--round"  height="96" outlined width="96">
                        <img class="my-1" :src="require(`@/assets/appAsset-brand--axteroid-icon.svg`)" width="56" />
                      </v-sheet>
                    </v-col>
                    <v-col cols="4">
                      <v-sheet class="px-3 py-1 my-3 ml-auto" outlined rounded="md" width="200">
                        <v-list-item>
                          <v-list-item-icon class="ml-3 mr-0"><img :src="require(`@/assets/brands/brand--axteroid.svg`)" alt="brand--axteroid" height="28"></v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title class="subtitle-2 text--primary">OSE</v-list-item-title>
                            <v-list-item-subtitle class="body-1 text--secondary">AX OSE</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <span class="flow-destination--1-2--active"><img :src="require(`@/assets/product-compliance/appAsset--compliance--flow-destination--1-2--active--blue.svg`)"></span>
                      </v-sheet>
                      <v-sheet class="px-3 py-1 my-3 ml-auto connector--warning" outlined rounded="md" width="200">
                        <v-tooltip color="bgSearch" left max-width="280px" transition="scale-transition" nudge-left="30">
                          <template v-slot:activator="{on}">
                            <v-list-item v-on="on" :to="{ name: 'ComplianceTask', query: { destination: 'sunat' } }">
                              <v-list-item-icon class="ml-3 mr-0"><img :src="require(`@/assets/brands/brand--sunat.svg`)" alt="brand--sunat" height="28"></v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title class="subtitle-2 text--primary">Regulador</v-list-item-title>
                                <v-list-item-subtitle class="body-1 text--secondary">SUNAT</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <span v-on="on" class="flow-destination--2-2--failed"><img :src="require(`@/assets/product-compliance/appAsset--compliance--flow-destination--2-2--failed--yellow.svg`)"></span>
                          </template>
                          <span class="d-block px-4 py-3 body-1 text--secondary">Ver en el Task Manager</span>
                        </v-tooltip>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- end process -->
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import ListViewMixin from '@/mixins/ListViewMixin'
import ChartBar from '@/components/charts/ChartBar'
import ChartDoughnut from '@/components/charts/ChartDoughnut'

export default {
  components: {
    ChartBar,
    ChartDoughnut
  },
  mixins: [
    ListViewMixin
  ],
  data: () => ({
    currentItem: {},
    modalRedirect: false,
    modalErrorInf: false,
    count: 10,
    selectedItem: 0,
    week: {
      labels: ['', '', '', '', '', '', ''],
      datasets: [
        { data: [10, 10, 10, 10, 10, 10, 10] }
      ]
    },
    month: {
      labels: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      datasets: [
        { data: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10] }
      ]
    },
    chats: [
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
        name: 'Carlos Daza',
        title: 'Brunch this weekend?',
        subtitle: `<span class="text--primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`
      },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
        name: 'Pablo Bartel',
        title: 'Summer BBQ <span class="grey--text text--lighten-1">4</span>',
        subtitle: `<span class="text--primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`
      },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
        name: 'Catalina C.',
        title: 'Oui oui',
        subtitle: '<span class="text--primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?'
      },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
        name: 'Antonia Daza',
        title: 'Birthday gift',
        subtitle: '<span class="text--primary">Trevor Hansen</span> &mdash; Have any ideas about what we should get Heidi for her birthday?'
      },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
        name: 'Aliosha Bertini',
        title: 'Recipe to try',
        subtitle: '<span class="text--primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.'
      }
    ],
    systems: [
      {
        name: 'SUNAT',
        type: 'destination',
        brand: 'brand--sunat.svg',
        standard: 'Regulador',
        status: 'error',
        alerts: [
          {
            title: 'No coincide importe con Axteroid',
            description: 'Wish I could come, but I\'m out of town this weekend.'
          },
          {
            title: 'Documentos no existen en SUNAT',
            description: 'Wish I could come, but I\'m out of town this weekend.'
          },
          {
            title: 'No coincide importe con Axteroid',
            description: 'Wish I could come, but I\'m out of town this weekend.'
          }
        ]
      },
      {
        name: 'Korber',
        type: 'source',
        brand: 'brand--korber.svg',
        standard: 'C. Distribución',
        status: 'error',
        alerts: [
          {
            title: 'Documentos no existen en C. Distribución',
            description: 'Wish I could come, but I\'m out of town this weekend.'
          },
          {
            title: 'Documentos no existen en C. Distribución',
            description: 'Wish I could come, but I\'m out of town this weekend.'
          }
        ]
      },
      {
        name: 'NCR',
        type: 'source',
        brand: 'brand--ncr.svg',
        standard: 'Tiendas / PoS',
        status: 'ok'
      },
      {
        name: 'SAP',
        type: 'source',
        brand: 'brand--sap.svg',
        standard: 'Back-office',
        status: 'ok'
      },
      {
        name: 'Axteroid',
        type: 'destination',
        brand: 'brand--axteroid.svg',
        standard: 'Emisor',
        status: 'ok'
      },
      {
        name: 'AX OSE',
        type: 'destination',
        brand: 'brand--axteroid.svg',
        standard: 'OSE',
        status: 'ok'
      }
    ],
    alertGroups: [0, 1],
    foo: true
  }),
  methods: {
    getCurrent () {
      //
    },
    handleModalRedirect () {
      this.modalRedirect = false
      this.$router.push({name: 'DocumentsRetrieve', params: {id: 'doc_1H5BlmXOI0B6UevnDi' }}).catch(() => {})
    },
    handleErrorInf (item = {}) {
      this.modalErrorInf = true
    }
  }
}
</script>
<style>
@keyframes moveUpAndDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-7px);
  }
}

.planet {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 175px;
  z-index: 1;
}

/*
.planet .planet-front ,
.planet .planet-back {
  position: absolute;
  top: 0;
  left: 100px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top;
} */

.planet::after {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 120px;
  background-image: url('~@/assets/product-compliance/appAsset--planet--bg.png');
  top: 60px;
  background-size: 760px;
}

.planet::before {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 120px;
  background-image: url('~@/assets/product-compliance/appAsset--planet--light.png');
  top: 55px;
  left: 230px;
  background-size: 300px;
  animation: moveUpAndDown 5s ease-in-out infinite;
}

.gradient--blue {
  /* background-image: url('~@/assets/brands/bg--compliance--main-operation-rate.svg'), radial-gradient(circle, rgba(50,133,226,1) 0%, rgba(36,66,210,1) 100%); */
  /* background-image: url('~@/assets/brands/bg--compliance--main-operation-rate.svg'), linear-gradient(329deg, rgba(2,0,36,1) 0%, rgba(130,52,219,1) 39%, rgba(73,174,170,1) 100%); */
  background-image: url('~@/assets/product-compliance/bg--compliance--main-operation-rate.svg'), linear-gradient(329deg, rgba(2,0,36,1) 13%, rgba(4,35,178,1) 75%, rgba(3,82,204,1) 85%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.v-sheet--round {
  border-radius: 50% !important;
}

.connector--failed {
  border-color: #C62828 !important;
  background-color: #FFEBEE !important;
}

.connector--warning {
  border-color: #FFE082 !important;
  background-color: #FFF8E1 !important;
}

.flow-connector--1-3--active {
  position: absolute;
  margin-left: 169px;
  margin-top: -47px;
  z-index: 1;
}

.flow-connector--1-3--active img {
  height: 100px;
}

.flow-connector--2-3--active {
  position: absolute;
  margin-left: 170px;
  margin-top: -52px;
  z-index: 1;
}

.flow-connector--2-3--active img {
  height: 40px;
}

.flow-destination--3-3--failed {
  position: absolute;
  margin-left: 170px;
  margin-top: -118px;
}

.flow-destination--3-3--failed img {
  height: 100px;
}

.flow-destination--1-2--active {
  position: absolute;
  margin-left: -157px;
  margin-top: -44px;
  z-index: 2;
}

.flow-destination--1-2--active img {
  height: 54px;
}

.flow-destination--2-2--failed {
  position: absolute;
  margin-left: -158px;
  margin-top: -77px;
  z-index: 1;
}

.flow-destination--2-2--failed img {
  height: 60px;
}

.flow {
  background: url('~@/assets/product-compliance/appAsset--compliance--main-operation-flow.svg');
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
}

.v-application .text-h1 {
  line-height: 4rem;
}

.v-col--outlined {
  border-left: 1px solid #CFD8DC;
}

.v-application .v-alert.amber.lighten-5 {
  border-color: #FFE082 !important;
  border-width: 1px;
  border-style: solid;
}
</style>